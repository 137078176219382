.footer-custom {
    font-family: Reckless TRIAL-Light !important;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    margin-top: 20px;
    color: var(--white);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}
.homepage-main .socials{
    gap: 11px!important;
}
.invert-icon{
    filter: invert(1);
}
.course-progress .oxford-logo{
    min-width: unset!important;
}
.course-progress .university-logos{
    flex-wrap: nowrap!important;
}
@media screen and (max-width: 768px) {
    .copyright-2023-leg{
        white-space: normal;
    }
}
.user-dashboard .flex-col-3{
    min-height: unset!important;
}
.footer .footer-logo-block{
    align-items: center;
    display: flex;
    flex-direction: column;
}
.footer .socials{
    display: flex;
    gap: 10px;
}
.footer .copyright-zone{
    gap: unset!important;
}