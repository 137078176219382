.faq-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
}
.faq-container p{
    font-size: var(--font-size-m);
}

.faq-container h1 {
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    color: var(--cobalt);
    margin-bottom: 20px;
}

.faq-intro {
    text-align: center;
    font-size: 20px;
    line-height: 1.6;
    color: var(--cobalt);
    max-width: 800px;
    margin: 0 auto 60px auto;
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.faq-item {
    border-bottom: 1px solid #f0f0f0;
}

.faq-question {
    display: flex;
    align-items: center;
    padding: 24px 0;
    cursor: pointer;
    position: relative;
}

.faq-marker {
    width: 4px;
    height: 80px;
    background-color: var(--cobalt);
    margin-right: 24px;
    border-radius: 2px;
}

.faq-question h3 {
    flex: 1;
    font-size: 20px;
    font-weight: 600;
    color: var(--cobalt);
    margin: 0;
}

.faq-icon {
    transition: transform 0.3s ease;
}

.faq-icon.open {
    transform: rotate(180deg);
}

.faq-answer {
    padding: 0 0 24px 28px;
    margin-left: 24px;
    color: #666;
    line-height: 1.6;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .faq-container h1 {
        font-size: 36px;
    }

    .faq-intro {
        font-size: 18px;
    }

    .faq-question h3 {
        font-size: 18px;
    }

    .faq-marker {
        height: 60px;
    }
}
.faq-item .faq-link{
    color: var(--cobalt) !important;
}
.faq-item .faq-link:not(.no-line-break){
    line-break: anywhere;
}

.faq-item .faq-link:hover{
    text-decoration: underline!important;
}
.faq-item b{
    font-weight: 500;
}
.faq-item img{
    width: 100%;
    margin-top: 20px;
    max-width: 700px;
}
.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    margin-top: 40px;
    margin-bottom: 40px;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
